<template>
  <div class="" v-if="partner && partner.id">
    <div class="mb-6 flex flex-row justify-start items-center">
      <BaseButton
        naked
        :progress="postsGenerating"
        label="generate posts"
        @click="generatePosts"
      />
    </div>

    <!-- posts -->
    <div class="mt-10">
      <h2 class="text-xl">Onboarding [our]</h2>
      <div class="mt-5 flex flex-row items-start flex-wrap">
        <!-- our post -->
        <div
          class="w-60 h-60 bg-gray-300 flex flex-center"
          v-if="!posts.our.post.url"
        >
          <BaseLoading v-if="postIsGenerated('post-our-post')" />
          <span class="inline-block" v-else>not generated</span>
        </div>
        <img class="w-60" :src="posts.our.post.url" v-if="posts.our.post.url" />

        <!-- our story -->
        <div
          class="w-60 h-60 ml-4 bg-gray-300 flex flex-center"
          v-if="!posts.our.story.url"
        >
          <BaseLoading v-if="postIsGenerated('post-our-story')" />
          <span class="inline-block" v-else>not generated</span>
        </div>
        <img
          class="w-60 ml-4"
          :src="posts.our.story.url"
          v-if="posts.our.story.url"
        />
      </div>
    </div>
    <div class="mt-10">
      <h2 class="text-xl">Onboarding [their]</h2>
      <div class="mt-5 flex flex-row items-start flex-wrap">
        <!-- their post -->
        <div
          class="w-60 h-60 bg-gray-300 flex flex-center"
          v-if="!posts.their.post.url"
        >
          <BaseLoading v-if="postIsGenerated('post-their-post')" />
          <span class="inline-block" v-else>not generated</span>
        </div>
        <img
          class="w-60"
          :src="posts.their.post.url"
          v-if="posts.their.post.url"
        />

        <!-- their story -->
        <div
          class="w-60 h-60 ml-4 bg-gray-300 flex flex-center"
          v-if="!posts.their.story.url"
        >
          <BaseLoading v-if="postIsGenerated('post-their-story')" />
          <span class="inline-block" v-else>not generated</span>
        </div>
        <img
          class="w-60 ml-4"
          :src="posts.their.story.url"
          v-if="posts.their.story.url"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mixinGlobal from "@/mixins/mixinGlobal"
import {ArrowLeftIcon} from "@vue-hero-icons/outline"
import {fn, storage} from "@/globals"
const generatePosts = fn.httpsCallable("calls-admin-generatePosts")

export default {
  name: "PartnerOnboardingPosts",
  mixins: [mixinGlobal],
  components: {
    ArrowLeftIcon,
  },
  props: {
    partner: {
      type: Object,
    },
  },
  data() {
    return {
      post: {
        kind: "partners/onboarding",
        ops: ["our", "their"],
        layouts: ["post", "story"],
      },
      postsGenerating: false,
      posts: {
        our: {
          post: {
            url: null,
          },
          story: {
            url: null,
          },
        },
        their: {
          post: {
            url: null,
          },
          story: {
            url: null,
          },
        },
      },
    }
  },
  computed: {},
  watch: {
    partner: {
      async handler(newValue, oldValue) {
        await this.getPostURLs()
      },
    },
  },
  async created() {},
  async mounted() {},
  methods: {
    async generatePosts() {
      this.postsGenerating = true
      let response = await generatePosts({
        post: this.post,
        partnerId: this.partner.id,
      })
      await this.getPostURLs()
      this.postsGenerating = false
    },
    postIsGenerated(postName) {
      if (!this.partner.postsGenerated) return false
      return this.partner.postsGenerated.includes(postName)
    },
    async getPostURLs() {
      let posts = []
      this.post.ops.forEach((op) => {
        this.post.layouts.forEach((layout) => {
          posts.push({
            kind: this.post.kind,
            op,
            layout,
          })
        })
      })

      await Promise.all(
        posts.map(async (post) => {
          return new Promise(async (resolve, reject) => {
            let fileName = `post-${post.op}-${post.layout}.jpeg`
            let fullPath = `partners/${
              this.partner.id
            }/posts/${post.kind.replaceAll("/", "-")}/${fileName}`
            let postPathRef = storage.ref(fullPath)
            console.log(fullPath)
            try {
              let postUrl = await postPathRef.getDownloadURL()
              console.log(postUrl)
              this.posts[post.op][post.layout].url = postUrl
              resolve()
            } catch (e) {
              console.error(e)
            }
          })
        })
      )
    },
  },
}
</script>

<style lang="less" scoped></style>
