<template>
  <div class="">
    <div class="mb-6 flex flex-row justify-start items-center">
      <BaseButton naked class="mr-4" @click="$router.push({name: 'partners'})"
        ><ArrowLeftIcon class="text-cdlblack dark:text-white"
      /></BaseButton>
      <BaseButton naked label="edit" @click="edit()" v-if="mode == 'view'" />
      <BaseButton label="save" @click="save()" v-if="mode == 'edit'" />
      <BaseButton
        danger
        class="ml-4"
        :progress="progressDelete"
        :label="confirmingDelete ? 'confirm' : 'delete'"
        @click="remove()"
        v-if="mode == 'edit'"
      />
      <BaseButton
        naked
        class="ml-4"
        label="cancel"
        @click="cancel()"
        v-if="mode == 'edit'"
      />
      <BaseButton
        naked
        class="ml-4"
        label="preview widget"
        @click="previewWidget()"
        v-if="mode == 'view'"
      />
      <BaseButton
        naked
        class="ml-4"
        label="re-fetch historical txs"
        :progress="progressRefetchingTxs"
        @click="refetchHistoricalTxs()"
        v-if="mode == 'view'"
      />
    </div>

    <!-- {{ partner.name }}{{ truncateAddress(partner.ethAddress) }}
    {{ roundAmount(partner.totalDonations.eth) }}
    {{ convert(partner.totalDonations.eth, "eth", "usd", 0).toLocaleString() }} -->
    <div v-if="partner">
      <!-- share -->
      <div
        class="
          mb-6
          p-4
          border border-gray-300
          dark:border-gray-700
          rounded-xl
          inline-block
        "
      >
        <span class="uppercase font-bold tracking-wider text-xs"
          >share with partner:</span
        >
        <p class="font-mono">
          <span
            class="
              hover:text-gray-700
              dark:hover:text-gray-500 dark:text-white
              text-sm
            "
            style="cursor: copy"
            @click="copyToClipboard(previewWidgetURI)"
            >{{ previewWidgetURI }}</span
          >
        </p>
      </div>

      <!-- fields -->
      <div class="">
        <BaseInput
          class="pb-3"
          label="id"
          placeholder=""
          mode="view"
          v-model="partner.id"
          @input="updated"
        />
        <BaseInput
          class="pb-3"
          :label="!partner.name || partner.name.length < 2 ? '⚠️ name' : 'name'"
          placeholder=""
          :mode="mode"
          v-model="partner.name"
          @input="updated"
        />
        <BaseInput
          class="pb-3 font-mono"
          :label="!partner.ethAddress ? '⚠️ ETH address' : 'ETH address'"
          placeholder=""
          :mode="mode"
          v-model="partner.ethAddress"
          @input="updated"
        />
        <BaseSelect
          class="pb-3"
          :label="!partner.country ? '⚠️ country' : 'country'"
          :mode="mode"
          v-model="partner.country"
          :options="countryOptions"
          @input="updated"
        />
        <BaseSelect
          class="pb-3"
          :label="!partner.kind ? '⚠️ kind' : 'kind'"
          :mode="mode"
          v-model="partner.kind"
          :options="kindOptions"
          @input="updated"
        />
        <BaseInput
          class="pb-3"
          :label="!partner.websiteURL ? '⚠️ website URL' : 'website URL'"
          placeholder=""
          :mode="mode"
          v-model="partner.websiteURL"
          @input="updated"
        />

        <!-- logos -->
        <div>
          <div>
            <span
              class="block text-sm font-medium text-gray-700 dark:text-gray-500"
              ><span v-if="!partner.logos || partner.logos.length < 1">⚠️&nbsp;</span>logos</span
            >
            <div v-if="partner.logos">
              <div v-for="logo in partner.logos" :key="logo.id">
                <div class="my-4 flex flex-row items-center">
                  <img class="h-16 block" :src="logoUrl(logo)" />
                  <div class="ml-5">
                    <span class="text-sm" v-if="logo.mimeType == 'image/png'"
                      >bitmap [{{ logo.extension }}]</span
                    >
                    <span
                      class="text-sm"
                      v-if="logo.mimeType == 'image/svg+xml'"
                      >vector [{{ logo.extension }}]</span
                    >
                    <div class="flex flex-row">
                      <span
                        class="mr-2 text-sm"
                        :class="[
                          {'dark:text-white': logo.color == 'color'},
                          {
                            'text-gray-300 dark:text-gray-700 hover:text-gray-500 dark:hover:text-gray-500':
                              logo.color && logo.color != 'color',
                          },
                          {'cursor-pointer': mode == 'edit' || !logo.color},
                          {'text-red-500': !logo.color}
                        ]"
                        @click="setLogoColor(logo, 'color')"
                        v-if="mode == 'edit' || logo.color == 'color' || !logo.color"
                        >color</span
                      >
                      <span
                        class="mr-2 text-sm"
                        :class="[
                          {'dark:text-white': logo.color == 'black'},
                          {
                            'text-gray-300 dark:text-gray-700 hover:text-gray-500 dark:hover:text-gray-500':
                              logo.color && logo.color != 'black',
                          },
                          {'cursor-pointer': mode == 'edit' || !logo.color},
                          {'text-red-500': !logo.color}
                        ]"
                        @click="setLogoColor(logo, 'black')"
                        v-if="mode == 'edit' || logo.color == 'black' || !logo.color"
                        >black</span
                      >
                      <span
                        class="mr-2 text-sm"
                        :class="[
                          {'dark:text-white': logo.color == 'white'},
                          {
                            'text-gray-300 dark:text-gray-700 hover:text-gray-500 dark:hover:text-gray-500':
                              logo.color && logo.color != 'white',
                          },
                          {'cursor-pointer': mode == 'edit' || !logo.color},
                          {'text-red-500': !logo.color}
                        ]"
                        @click="setLogoColor(logo, 'white')"
                        v-if="mode == 'edit' || logo.color == 'white' || !logo.color"
                        >white</span
                      >
                    </div>
                    
                  </div>
                  <BaseButton
                      class="ml-4"
                      naked
                      danger
                      size="s"
                      label="delete"
                      @click="deleteLogo(logo)"
                      v-if="mode == 'edit'"
                    />
                </div>
              </div>
            </div>

            <div class="mt-10" v-if="mode == 'edit'">
            <span>upload [SVG (preferred) or PNG only]</span><br>
            <input
              class="mt-2"
              ref="partnerLogosUpload"
              type="file"
              accept="image/png,image/svg+xml"
              multiple
              id="partnerLogos"
            />
            </div>
          </div>
        </div>

        <hr class="border-gray-500 my-5" />
        <span class="text-2xl pb-5 block">private</span>
        <BaseInput
          class="pb-3"
          label="bank account"
          placeholder=""
          :mode="mode"
          v-model="partnerPrivateSnapshot.bankAccount"
          @input="updated"
          v-if="partnerPrivateSnapshot"
        />
        <BaseInput
          class="pb-3"
          label="note"
          placeholder=""
          :mode="mode"
          long
          v-model="partnerPrivateSnapshot.note"
          @input="updated"
          v-if="partnerPrivateSnapshot"
        />

        <hr class="border-gray-500 my-5" />
        <span class="text-2xl pb-5 block">Social posts</span>
        <PartnerOnboardingPosts :partner="partner" v-if="partner" />
      </div>
    </div>
  </div>
</template>

<script>
import mixinGlobal from "@/mixins/mixinGlobal"
import {ArrowLeftIcon} from "@vue-hero-icons/outline"
import {uniqueId, storage, fn} from "@/globals"
import PartnerOnboardingPosts from '@/components/Partners/PartnerOnboardingPosts.vue'
import countries from '@/lists/countries'
const refetchHistoricalTxs = fn.httpsCallable(
  "calls-admin-partner-refetchHistoricalTxs"
)

export default {
  name: "Partner",
  mixins: [mixinGlobal],
  components: {
    ArrowLeftIcon,
    PartnerOnboardingPosts,
  },
  data() {
    return {
      mode: "view",
      confirmingDelete: false,
      progressDelete: false,
      progressRefetchingTxs: false,
      partner: {},
      partnerPrivateSnapshot: {},
      uploadedLogos: [],
      loadedLogos: [],

      // also in functions/common/postGenerator/posts/partners/onboarding/our
      kindOptions: [{
        value: 'pet-owner',
        name: 'pet owner'
      }, {
        value: 'shelter',
        name: 'shelter'
      }, {
        value: 'shelter-doge',
        name: 'doge shelter'
      }, {
        value: 'shelter-kitty',
        name: 'kitty shelter'
      }, {
        value: 'foundation',
        name: 'foundation'
      }, {
        value: 'other',
        name: 'other'
      }]
    }
  },
  computed: {
    partners() {
      return this.$store.state.partners.partners
    },
    partnerPrivate() {
      return this.$store.state.partners.partnerPrivate
    },
    loadedPartners() {
      return this.$store.state.partners.loadingState.partners == "loaded"
    },
    previewWidgetURI() {
      return `${process.env.VUE_APP_URI_WEB}/widget?partnerId=${this.partner.id}`
    },
    countryOptions() {
      return countries.map(c => {
        return {value: c.code, name: c.name}
      })
    }
  },
  watch: {
    partner: {
      async handler(newValue, oldValue) {
        await this.$store.dispatch("partners/bindPartnerPrivate", {
          partnerId: this.partner.id,
        })
        this.getPartnerPrivateFromDb()
        await this.getLogoURLs()
      },
    },
  },
  async created() {},
  mounted() {
    this.getPartnerFromDb()

    if (this.$route.params.mode) this.mode = this.$route.params.mode
  },
  destroy() {
    this.$store.dispatch("unbindPartnerPrivate")
  },
  methods: {
    getPartnerFromDb() {
      let partner = this.partners.find(
        (p) => p.id == this.$route.params.partnerId
      )
      this.partner = JSON.parse(JSON.stringify(partner))
      this.partner.id = partner.id
    },
    getPartnerPrivateFromDb() {
      this.partnerPrivateSnapshot = JSON.parse(
        JSON.stringify(this.partnerPrivate)
      )
    },
    logoUrl(logo) {
      let loadedLogo = this.loadedLogos.find((l) => l.id == logo.id)
      if (!loadedLogo) return false
      return loadedLogo.url
    },
    async getLogoURLs() {
      if (!this.partner.logos) return

      console.log("getLogoURLs")
      return await Promise.all(
        this.partner.logos.map(async (logo) => {
          let url = await this.getLogoURL(logo)
          this.loadedLogos.push({
            id: logo.id,
            url,
          })
          return
        })
      )
    },
    async getLogoURL(logo) {
      if (!logo) return false
      let fileName = logo.fileName
      let fullPath = `partners/${this.partner.id}/logos/${fileName}`
      let fileRef = storage.ref(fullPath)
      // console.log(fileRef)
      let url = await fileRef.getDownloadURL()
      // console.log(url)
      return url
    },
    async uploadLogos() {
      console.log("uploadLogos(): start")
      let fileList = Array.from(this.$refs.partnerLogosUpload.files)
      // console.log(fileList)
      await Promise.all(
        fileList.map(async (file) => {
          return await this.uploadLogo(file)
        })
      )
      let lala = await this.getLogoURLs()
      return lala
    },
    async uploadLogo(file) {
      let extension =
        file.type == "image/png"
          ? "png"
          : file.type == "image/svg+xml"
          ? "svg"
          : false
      let id = uniqueId(6)
      console.log("id", id)
      let fileName = `${id}.${extension}`
      this.uploadedLogos.push({
        fileName,
        id,
        extension,
        originalName: file.name,
        mimeType: file.type,
      })
      let fileRef = storage.ref(`partners/${this.partner.id}/logos/${fileName}`)
      console.log("uploading file:", fileName)
      let uploadTask = fileRef.put(file)

      uploadTask.on(
        "state_changed",
        function progress(snapshot) {},
        function error(err) {
          console.log("error", err)
          reject(err)
        },
        function complete() {
          console.log("resolving")
        }
      )
    },
    async setLogoColor(logo, color) {
      if (logo.color && this.mode != 'edit') return
      let uploadedLogos = this.partner.logos
      let logoIndex = uploadedLogos.findIndex((l) => l.id == logo.id)
      uploadedLogos[logoIndex].color = color
      await this.$store.dispatch("partners/update", {
        partner: this.partner,
        uploadedLogos,
      })
      this.getPartnerFromDb()
    },
    async deleteLogo(logo) {
      let fileName = logo.fileName
      let fileRef = storage.ref(`partners/${this.partner.id}/logos/${fileName}`)
      await fileRef.delete()
      let uploadedLogos = this.partner.logos.filter((l) => l.id != logo.id)
      await this.$store.dispatch("partners/update", {
        partner: this.partner,
        uploadedLogos,
      })
      this.getPartnerFromDb()
      return
    },
    updated(v) {},
    edit() {
      this.mode = "edit"
    },
    async save() {
      this.mode = "view"
      await this.uploadLogos()
      console.log("dispatching update partner")
      let uploadedLogos = this.uploadedLogos
      if (this.partner.logos)
        uploadedLogos = [...uploadedLogos, ...this.partner.logos]
      await this.$store.dispatch("partners/update", {
        partner: this.partner,
        partnerPrivate: this.partnerPrivateSnapshot,
        uploadedLogos,
      })
      console.log("partner updated")
      this.getPartnerFromDb()
    },
    async remove() {
      if (this.confirmingDelete) {
        this.progressDelete = true
        await this.$store.dispatch("partners/delete", this.partner)
        this.progressDelete = false
        this.mode = "view"
        this.confirmingDelete = false
        console.log("partner deleted")
        this.$router.push({name: "partners"})
      } else {
        this.confirmingDelete = true
      }
    },
    cancel() {
      this.mode = "view"
      this.confirmingDelete = false
      this.getPartnerFromDb()
    },
    previewWidget() {
      window.open(this.previewWidgetURI, "_blank").focus()
    },
    async refetchHistoricalTxs() {
      this.progressRefetchingTxs = true
      let response = await refetchHistoricalTxs({
        partnerId: this.partner.id,
        partnerEthAddress: this.partner.ethAddress,
      })
      this.progressRefetchingTxs = false
    },
  },
}
</script>

<style lang="less" scoped>
.partner-eth-address {
  cursor: copy;
}
</style>
